.ResponsiveImage {
  transition: filter .5s ease;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;

  &:not(.loaded) {
    filter: blur(25px);
  }
}
