.Info {

  h1 {
    margin-top: 1rem;
  }

  h1, p {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }

  a {
    color: #333333;
    &:hover {
      color: #666;
      text-decoration: underline;
    }
  }
}

@media screen and (min-width: 768px) {

  .Info {

    width: 30vw;
    height: auto;
    margin: auto 0;

  }

}
