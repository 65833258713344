.AboutSplash {

  width: 100vw;

  .carousel-item {
    height: 100vh;
  }

  .carousel-control-prev-icon, .carousel-control-next-icon {
      opacity: 0;
      filter:alpha(opacity=0); /* IE support */
  }

}

@media screen and (min-width: 768px) {

  .AboutSplash {

    width: 70vw;

  }

}
