@import "../node_modules/bootstrap/scss/bootstrap";
@import url(https://fonts.googleapis.com/css?family=Roboto:100&display=swap);

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  line-height: 1.5em;
}

@media screen and (min-width: 768px) {

  .App {

  }

}
