.About {

}

@media screen and (min-width: 768px) {

  .About {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
  }

}
